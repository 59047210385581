<!-- src/views/Home.vue -->

<template>
  <div>
    <h2>Events organized by me </h2>
    <div v-if="!loggedIn">
      <p>Please log in to proceed:</p>
      <router-link to="/login">Go to Login</router-link>
    </div>
    <div v-else>
      <p>You are set to succeed ! Share your events with friends and family.</p>
    </div>
    
    <v-row  style="padding-bottom: 120px !important;" v-if="events.length > 0">
      <v-col
        v-for="event in events" 
        :key="event.id"
        cols="12"
        md="4"
        class="px-2 py-1" >
        <v-card class="event-card">
          <v-card-title>{{ event.title }}</v-card-title>
          <v-card-subtitle>Win Gift Reward: {{ parseFloat(event.reward).toLocaleString() }}pcs</v-card-subtitle>
          <v-card-text>
            <v-icon>mdi-timer</v-icon> {{ calculateTimeLeft(event.endTime) }} <br/>
            <v-icon>mdi-ticket</v-icon> {{ event.paidType === 'free' || null ? 'Free' : 'Entry Fee: ' + event.ticketPrice + 'pcs' }}<br/>
            <v-icon>mdi-user</v-icon> {{ event.privacyType == 'public' ? 'For Everyone' : 'For community members only' }}
          </v-card-text>
          <v-card-actions style="border-top: 1px solid rgba(0, 0, 0, 0.575) !important;">
            <v-btn style="color: rgba(0, 0, 0, 0.863) !important;" :class="calculateTimeLeft(event.endTime) == 'Ended' ? 'grey': 'secondary color-black'" @click="joinEvent(event)">
              <template v-if="calculateTimeLeft(event.endTime) == 'Ended'">View Challenge</template>
              <template v-else>View</template>
            </v-btn>
            <v-btn @click="showShareSheet(event)">
              <v-icon>mdi-share-variant</v-icon> 
              Share
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-btn align="center" class="mt-2" v-if="showViewMoreButton" @click="loadMoreChallenges">View More Challenges</v-btn>
      </v-col>
    </v-row>
    <v-row style="padding-bottom: 120px !important;" v-else>
      <v-col>
        No Event available, <br/>Create an Event
      </v-col>
    </v-row>

    <v-bottom-sheet v-model="shareSheet" right>
      <v-sheet>
        <v-row class="px-2 pb-2">
          
          <v-col cols="12" class="pb-0">
            Share with your family and loved ones
          </v-col>
          <v-col cols="12" class="px-1">
            <v-btn @click="copyLinkToClipboard(`${baseURL}${selectedEvent.uniqueRef}`)">
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
            &nbsp;
            <v-btn class="mt-1 mr-1" v-for="platform in socialPlatforms" :key="platform.name" @click="shareOnPlatform(platform, selectedEvent)">
              <v-icon>{{ platform.icon }}</v-icon>
              {{ platform.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
    
    <v-snackbar v-model="snackbar" timeout="3000">
      Link copied to clipboard!
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: [
        //{ id: 1, title: 'Free Event 1', reward: 'Awesome Prize', timeLeft: 2, paidTime: 'free', ticketPrice: '10' }
      ],
      baseURL: 'https://learnbread.com/events/',
      shareSheet: false,
      selectedEvent: null,
      socialPlatforms: [
        { name: 'WhatsApp', icon: 'mdi-whatsapp' },
        { name: 'Email', icon: 'mdi-email' },
        { name: 'Facebook', icon: 'mdi-facebook' },
        { name: 'Twitter', icon: 'mdi-twitter' },
        { name: 'LinkedIn', icon: 'mdi-linkedin' },
        { name: 'Telegram', icon: 'mdi-telegram' }
        // Add more social media platforms as needed
      ],
      snackbar: false,
      page: 1,
      showViewMoreButton: false
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    userInfo() {
      return this.$store.getters['auth/userInfo']
    }
  },
  created() {
    this.$store.commit('loading/setLoading', true, { root: true })
    this.checkEvents()
  },
  methods: {
    async loadMoreChallenges() {
      this.page += 1

      this.checkEvents()
    },
    calculateTimeLeft(endDate) {
      const currentTime = new Date()
      const eventEndDate = new Date(endDate)
      const timeDifference = eventEndDate - currentTime // in milliseconds

      if (timeDifference <= 0) {
        // Event has ended
        //this.remainingTime = 0
        clearInterval(this.countdownInterval)

        return 'Ended'
      }

      const seconds = Math.floor(timeDifference / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      //this.remainingTime = timeDifference

      return `${days > 0 ? days + ' days, ' : ''}${hours % 24} hours, ${minutes % 60} mins left`
    },
    async checkEvents() {
      const credentials = {
        userProfile: this.$store.getters['auth/userInfo'],
        page: this.page
      }
      // Dispatch the action defined in your Vuex store module
      
      try {
        if (this.$store.getters['auth/isLoggedIn']) {
          const response = await this.$store.dispatch('wallet/checkMyEvents', credentials)

          if (response && response.success) {
            if (response.success) {
              ////console.log('our transactions are ', response.transactions)
              ////console.log('our balance is ', response.balance)
              //this.transactionHistory = response.transactions
              // Use the spread syntax to merge existing transactions with new transactions
              this.events = [...this.events, ...response.challenges]
              //this.walletAmount = response.balance
              // Check if there are more transactions to load
              this.showViewMoreButton = response.totalCount > this.events.length
            }
          }
        } else {
          this.$router.push('/login')
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        this.$store.commit('loading/setLoading', false, { root: true })
        //this.submitting = false
      }
    },
    joinEvent(event) {
      //const shareableLink = `${window.location.origin}/events/${event.uniqueRef}`;
      //const { event.uniqueRef }

      ////console.log('our event is ', event)
      // Use router-link or programmatic navigation to go to the EventDetails page
      
      ////console.log('our unique ref is ', event.uniqueRef)

      this.$router.push({ name: 'EventDetails', params: { uniqueRef: event.uniqueRef } })
      //////console.log('Joining the event:', event.name)
      // Add your logic for joining the event here
    },
    showShareSheet(event) {
      this.selectedEvent = event
      this.shareSheet = true
    },
    shareOnPlatform(platform, event) {
      switch (platform.name) {
      case 'WhatsApp':
        this.shareOnWhatsApp(event)
        break
      case 'Email':
        this.shareViaEmail(event)
        break
      case 'Facebook':
        this.shareOnFacebook(event)
        break
      case 'Twitter':
        this.shareOnTwitter(event)
        break
      case 'LinkedIn':
        this.shareOnLinkedIn(event)
        break
      case 'Telegram':
        this.shareOnTelegram(event)
        break
      // Add more cases for other social media platforms
      }
    },
    shareOnFacebook(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${link}`
      
      window.open(facebookShareUrl, '_blank')
      this.copyLinkToClipboard(link)
    },
    shareOnTwitter(event) {
      const link = `${this.baseURL}${event.uniqueRef}`// Replace with your actual event link
      const message = `Check out ${event.name}: ${link}`
      const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`
      
      window.open(twitterShareUrl, '_blank')
      this.copyLinkToClipboard(link)
    },
    shareOnLinkedIn(event) {
      const link = `${this.baseURL}${event.uniqueRef}`// Replace with your actual event link
      const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`
      
      window.open(linkedInShareUrl, '_blank')
      this.copyLinkToClipboard(link)
    },
    shareOnTelegram(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(link)}`
      
      window.open(telegramShareUrl, '_blank')
      this.copyLinkToClipboard(link)
    },
    shareOnWhatsApp(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const message = `Check out ${event.name}: ${link}`
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
      
      window.open(whatsappShareUrl, '_blank')
      this.copyLinkToClipboard(link)
    },
    shareViaEmail(event) {
      // Implement email sharing logic here
      // Example: You can open the user's default email client with a pre-filled email
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const subject = `Check out ${event.name}`
      const body = `Hey, I thought you might be interested in this event: ${link}`
      const emailLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      
      window.open(emailLink, '_blank')
      this.copyLinkToClipboard(link)
    },
    copyLinkToClipboard(link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          this.snackbar = true
        })
        .catch((error) => {
          console.error('Error copying to clipboard:', error)
        })
    },
    loginWithGoogle() {
      this.$store.dispatch('auth/loginWithGoogle')
    },
    loginToProceed() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.event-card {
  border: 0.1px solid rgba(0, 0, 0, 0.575) !important;
}
</style>